<template>
  <div>
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <div class="title head">
            <p class="h2" style="color: #558cef">รายการฝ่ายงาน</p>
          </div>
          <hr />
          <!-- search input -->
          <div class="custom-search row justify-content-between">
            <div class="col-12 col-md-3 mb-1">
              <b-button v-b-modal.modal-1>เพิ่มฝ่ายงาน</b-button>
            </div>
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>

                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      Excel
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>

          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'edit'" class="text-nowrap">
                  <b-button variant="outline-info" v-b-modal="`modal-edit${props.index}`">
                    <feather-icon icon="Edit3Icon" size="16" class="mx-1" />
                  </b-button>
                  <b-modal
                    :id="`modal-edit${props.index}`"
                    cancel-variant="outline-secondary"
                    ok-title="บันทึก"
                    cancel-title="ยกเลิก"
                    title="แก้ไขฝ่ายงาน"
                    size=""
                    @ok="edit_data(props.row)"
                    no-close-on-backdrop
                  >
                    <b-form>
                      <div>
                        <b-form-group label-cols="4" label-cols-lg="4" label="รหัสฝ่าย:">
                          <template v-slot:label> รหัสฝ่าย <span class="text-danger"> *</span> </template>
                          <b-form-input id="basicInput" v-model="props.row.dep_id" placeholder="รหัสฝ่าย" />
                        </b-form-group>
                        <b-form-group label-cols="4" label-cols-lg="4" label="ชื่อฝ่าย:">
                          <template v-slot:label> ชื่อฝ่าย <span class="text-danger"> *</span> </template>
                          <b-form-input id="basicInput" v-model="props.row.dep_name" placeholder="ชื่อฝ่าย" />
                        </b-form-group>
                        <b-form-group label-cols="4" label-cols-lg="4" label="ชื่อย่อฝ่าย:">
                          <template v-slot:label> ชื่อย่อฝ่าย <span class="text-danger"> *</span> </template>
                          <b-form-input v-model="props.row.dep_name_short" id="basicInput" placeholder="ชื่อย่อฝ่าย" />
                        </b-form-group>
                      </div>
                    </b-form>
                  </b-modal>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'insert'">
                  <span> </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => handlePageChange(value)"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>

    <!-- //model -->
    <div>
      <b-modal
        id="modal-1"
        cancel-variant="outline-secondary"
        ok-title="บันทึก"
        cancel-title="ยกเลิก"
        title="ฝ่าย"
        size="md"
        @ok="getdata"
        no-close-on-backdrop
      >
        <b-form>
          <div>
            <b-form-group label-cols="4" label-cols-lg="4" label="รหัสฝ่าย:">
              <template v-slot:label> รหัสฝ่าย <span class="text-danger"> *</span> </template>
              <b-form-input id="basicInput" v-model="dep_id" placeholder="รหัสฝ่าย" />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label="ชื่อฝ่าย:">
              <template v-slot:label> ชื่อฝ่าย <span class="text-danger"> *</span> </template>
              <b-form-input id="basicInput" v-model="dep_name" placeholder="ชื่อฝ่าย" />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label="ชื่อย่อฝ่าย:">
              <template v-slot:label> ชื่อย่อฝ่าย <span class="text-danger"> *</span> </template>
              <b-form-input id="basicInput" v-model="dep_name_short" placeholder="ชื่อย่อฝ่าย" />
            </b-form-group>
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import { BFormInput, BRow, BCol, BFormGroup } from 'bootstrap-vue';
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { BButton } from 'bootstrap-vue';
import { BFormFile } from 'bootstrap-vue';
import { BFormSelect } from 'bootstrap-vue';
import { BFormDatepicker } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import API from '@/views/connectDB/condb.js';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import _ from 'lodash';
export default {
  props: {
    rowData: Object,
  },
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
  },

  data() {
    return {
      goData: '',
      show: false,
      dep_id: '',
      dep_name: '',
      dep_name_short: '',
      pageLength: 10,
      total: '',
      dir: false,
      columns: [
        {
          label: 'รหัสกลุ่มงาน',
          field: 'work_gid',
        },
        {
          label: 'รหัสฝ่าย',
          field: 'dep_id',
        },
        {
          label: 'ชื่อฝ่าย',
          field: 'dep_name',
        },
        {
          label: 'รหัสย่อฝ่าย',
          field: 'dep_name_short',
        },
        {
          label: 'แก้ไข',
          field: 'edit',
          width: '5%',
        },
      ],
      rows: [],
      searchTerm: '',
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    console.log(this.rowData);
    _.isEmpty(this.rowData) ? this.$router.push({ path: '/workgroup' }) : true;
    const { work_gid } = this.rowData;
    const { access_token } = await this.access_token();
    const url = `${API}departments?_page=1&_limit=100&_sort=1&work_gid=${work_gid}`;
    const header = {
      headers: {
        Authorization: await access_token,
      },
    };
    const res = await axios.get(url, header);
    this.rows = res.data.message.data;
    this.total = res.data.message.total;
  },
  methods: {
    async edit_data(value) {
      // console.log(value);
      const { access_token } = await this.access_token();
      const url = `${API}departments/${value.id}`;
      const data = {
        dep_id: value.dep_id,
        dep_name: value.dep_name,
        dep_name_short: value.dep_name_short,
      };
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };

      const res = await axios.put(url, data, head);
      // console.log(res);
    },
    exportExcel() {
      saveExcel({
        data: '',
        fileName: 'รายการฝ่ายงาน',
        columns: [
          {
            title: 'รายการฝ่ายงาน',
            headerCellOptions: { textAlign: 'center' },
            children: [
              {
                title: 'รหัสกลุ่มงาน',
                field: 'work_gid',
              },
              {
                title: 'รหัสฝ่าย',
                field: 'dep_id',
              },
              {
                title: 'ชื่อฝ่าย',
                field: 'dep_name',
              },
              {
                title: 'รหัสย่อฝ่าย',
                field: 'dep_name_short',
              },
            ],
          },
        ],
      });
    },
    async getdata() {
      const { work_gid } = this.rowData;
      const { access_token } = await this.access_token();
      const data = {
        work_gid: work_gid,
        dep_id: this.dep_id,
        dep_name: this.dep_name,
        dep_name_short: this.dep_name_short,
      };
      const url = `${API}departments`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      if (this.rows === null && this.rows === undefined && this.rows === '' && this.rows === [] && this.rows === {}) {
        const res = await axios.post(url, data, head);
        // console.log(res.data.message);
        if (res.data.status === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'บันทึกข้อมูล',
            showConfirmButton: false,
            timer: 1500,
          });
          this.handlePageChange();
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        this.dep_id = this.dep_id.replace(' ', '');
        const QC = this.rows.find(({ dep_id }) => dep_id === this.dep_id);
        if (QC) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `ข้อมูลซ้ำ ${QC.dep_id} - ${QC.dep_name}`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          const res = await axios.post(url, data, head);
          // console.log(res.data);
          if (res.data.status === 200) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'บันทึกข้อมูล',
              showConfirmButton: false,
              timer: 1500,
            });
            this.handlePageChange();
          } else {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }
      }
    },
    async handlePageChange(currentPage) {
      this.show = true;
      const { work_gid } = this.rowData;
      const { access_token } = await this.access_token();
      const url = `${API}departments?_page=${currentPage}&_limit=${this.pageLength}&_sort=1&work_gid=${work_gid}`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      this.rows = res.data.message.data;
      this.total = res.data.message.total;
      this.show = false;
    },

    async access_token() {
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
